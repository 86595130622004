import {
  useEffect,
  useRef,
  useState,
  useMemo,
  useImperativeHandle,
  useLayoutEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import { PaymentRequestButtonElement, Elements } from '@stripe/react-stripe-js';
import braintree from 'braintree-web';
import styled from 'styled-components';
import { pushFBEvent, sendAnalyticsEvents } from '../../analytics/common';
import { setAmplitudeUserProperties } from '../../analytics/amplitude';
import { EVENTS } from '../../analytics/events';
import {
  selectEventsData,
  setEventDataList,
  selectAnalyticsData,
} from '../../store/events';
import {
  braintreePurchase,
  sendMainPurchaseRequest,
  selectFormValidity,
  selectID,
  selectName,
  selectShowPaymentError,
  selectSuccess,
  selectIfCardFormShown,
  setCardholderName,
  setIfCardFormShown,
  setShowPaymentError,
} from '../../store/checkout';
import {
  selectBtToken,
  selectStripeAccountName,
  selectToken,
  selectUserUuid,
  selectPaymentProvider,
} from '../../store/signup';
import {
  selectLandingType,
  selectPlan,
  selectPlanPrices,
  selectShowCheckout,
  selectDefaultPlanId,
  setPlan,
  setShowCheckout,
} from '../../store/plans';
import { PLANS } from '../../analytics/plans';
import AppContainer from '../../components/AppContainer';
import CardIcons from '../../icons/CardIcons';
import { setCheckoutLoader } from '../../store/loader';
import useWindowWidth from '../../hooks/useWindowWidth';
import useScrollToTop from '../../hooks/useScrollToTop';
import AppButton from '../../components/AppButton';
import Stripe from './components/Stripe';
import Braintree from './components/Braintree';
import PaypalButton from './components/PaypalButton';

const isStage = process.env.REACT_APP_ENV === 'stage';
const textAlign = navigator.language.startsWith('ar') ? 'right' : 'left';
const enablePaypal = process.env.REACT_APP_ENABLE_PAYPAL === 'true';

const CheckoutHeadWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 330px;
  margin-bottom: 24px;
  width: 100%;
  position: relative;
`;

const StyledBackButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 4px;
  cursor: pointer;
  position: absolute;
  left: 5px;
  top: 3px;
`;

const Title = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.font_24};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_29};
  color: ${(props) => props.theme.colors.title};
`;

const PaymentRequestContainer = styled.div`
  width: 100%;
  max-width: 330px;
  margin-bottom: 24px;
  div iframe {
    border-radius: 14px;
  }
`;

const PayWithText = styled.div`
  margin: 24px 0;
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font_14};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_24};
  color: ${(props) => props.theme.colors.checkout_or};
  text-align: center;
  text-transform: uppercase;
`;

const AlternativePaymentButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const StoreWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.main};
  box-shadow: 4px 6px 20px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  max-width: 327px;
  margin-top: 16px;
`;

const StoreButtons = styled.ul`
  width: 100%;
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  @media screen and (max-width: 350px) {
    flex-wrap: wrap;
    padding: 10px;
  }
`;

const StoreLi = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  min-width: 155px;
  padding-top: 20px;
  padding-bottom: 16px;
  :first-child {
    margin-right: 17px;
  }
  @media screen and (max-width: 350px) {
    :first-child {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`;

const StyledItemStoreWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const StyledItemStoreImg = styled.img`
  margin-right: 8px;
`;

const StyledItemStore = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font_16};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_19};
  color: ${(props) => props.theme.colors.title};
  margin-bottom: 4px;
  :last-child {
    margin-top: 8px;
    margin-bottom: 0;
  }
`;

const Disclaimer = styled.div`
  font-weight: ${(props) => props.theme.fontWeights.extraLight};
  font-size: ${(props) => props.theme.fontSizes.font_14};
  line-height: ${(props) => props.theme.lineHeights.lineHeight_24};
  color: ${(props) => props.theme.colors.description};
  padding: 0 19px 19px 19px;
  text-align: ${textAlign};
  & > span {
    text-decoration: underline;
  }
`;

const ArrowLeftIcon = styled.div`
  width: 10px;
  height: 18px;
  background-image: url(${'../assets/checkout/ArrowLeft.svg'});
`;

const style = {
  base: {
    color: '#000000',
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 300,
    fontFamily: 'Open Sans, Segoe UI, sans-serif',
    textAlign,

    '::placeholder': {
      color: '#ADADAD',
      letterSpacing: '1.2px',
      fontSize: '18px',
      lineHeight: '24px',
    },
  },
};

const CheckoutScreen = ({ implemented, stripePromise, paymentCallback }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showError = useSelector(selectShowPaymentError);
  const isFormValid = useSelector(selectFormValidity);
  const eventsData = useSelector(selectEventsData);
  const plansPrices = useSelector(selectPlanPrices);
  const isSuccess = useSelector(selectSuccess);
  const subscriptionId = useSelector(selectID);
  const [planDetails, setPlanDetails] = useState(null);
  const userUuid = useSelector(selectUserUuid);
  const token = useSelector(selectToken);
  const name = useSelector(selectName);
  const plan = useSelector(selectPlan);
  const landingType = useSelector(selectLandingType);
  const stripeAccountName = useSelector(selectStripeAccountName);
  const [load, setLoad] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const stripeInstance = useRef();
  const card = useRef();
  const expiration = useRef();
  const cvc = useRef();
  const cardholder = useRef(null);
  const braintreeInstance = useRef();
  const braintreeCardInstance = useRef();
  const braintreeThreeDSInstance = useRef();
  const paypal = useRef();
  const btToken = useSelector(selectBtToken);
  const analyticsParams = useSelector(selectAnalyticsData);
  const windowWidth = useWindowWidth();
  const showCheckout = useSelector(selectShowCheckout);
  const defaultPlanId = useSelector(selectDefaultPlanId);
  const cardFormVisited = useSelector(selectIfCardFormShown);
  const paymentProvider = useSelector(selectPaymentProvider);
  const isBraintree = paymentProvider === 'braintree';

  const landingsWithoutDisclaimer = useMemo(
    () => [
      'new',
      'new-discount',
      'freetrial-monthly',
      'freetrial-weekly',
      'month-trial',
      'fdtrial',
    ],
    []
  );
  const isShortDisclaimer = landingsWithoutDisclaimer.includes(landingType);

  useScrollToTop();

  useEffect(() => {
    if (plan) {
      setPlanDetails(PLANS[plan]);
    }
  }, [plan]);

  useEffect(() => {
    if (!isBraintree && stripePromise) {
      stripePromise.then((stripeObj) => {
        stripeInstance.current = stripeObj;
        const elements = stripeObj.elements();

        card.current = elements.create('cardNumber', {
          style,
          placeholder: '0000  0000  0000  0000',
        });
        expiration.current = elements.create('cardExpiry', {
          style,
          placeholder: t('MM/YY'),
        });
        cvc.current = elements.create('cardCvc', {
          style,
          placeholder: t('CVV'),
        });

        setLoad(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBraintree, stripePromise]);

  useLayoutEffect(() => {
    if (!isBraintree && stripeInstance.current && planDetails) {
      setPaymentRequest(null);
      const pr = stripeInstance.current.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: `GeoZilla ${planDetails?.description} plan`,
          amount: Math.round(planDetails?.periodPrice * 100),
        },
        displayItems: [
          {
            label: `GeoZilla ${planDetails?.description} plan`,
            amount: Math.round(planDetails?.periodPrice * 100),
          },
        ],
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        if (result && cardFormVisited && eventsData) {
          setPaymentRequest(pr);
        }
      });

      pr.on('paymentmethod', async (e) => {
        const successPayment = () => e.complete('success');
        const failedPayment = () => e.complete('fail');

        stripePurchaseClick(e, successPayment, failedPayment);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stripeInstance.current,
    planDetails,
    cardFormVisited,
    isBraintree,
    eventsData,
  ]);

  useEffect(() => {
    if (btToken && (isBraintree || enablePaypal)) {
      initializeBraintree(btToken);
    }
  }, [btToken, isBraintree]);

  const initializeBraintree = (token) => {
    braintree.client.create(
      { authorization: token },
      (clientErr, clientInstance) => {
        if (clientErr) {
          console.error('Error creating client:', clientErr);
          return;
        }

        braintreeInstance.current = clientInstance;

        if (enablePaypal) {
          braintree.paypal.create(
            {
              client: clientInstance,
            },
            (paypalErr, paypalInstance) => {
              if (paypalErr) {
                console.error('Error creating PayPal:', paypalErr);
                return;
              }

              paypal.current = paypalInstance;
            }
          );
        }
      }
    );
  };

  const onPayPalClick = () => {
    const localEventsData = { payment_method: 'Paypal' };
    const extendedEventsData = {
      ...eventsData,
      ...localEventsData,
    };
    sendAnalyticsEvents(EVENTS.subBuyTapped, extendedEventsData);
    dispatch(setCheckoutLoader({ show: true, type: 'checkout' }));
    sendAnalyticsEvents(EVENTS.paymentAnimationScreen, extendedEventsData);

    paypal.current.tokenize(
      {
        flow: 'vault',
        currency: 'USD',
      },
      (tokenizeErr, payload) => {
        if (tokenizeErr) {
          if (tokenizeErr.type !== 'CUSTOMER') {
            console.error('Error tokenizing:', tokenizeErr);
          }
          dispatch(setCheckoutLoader({ show: false, type: '' }));
          return;
        }

        setAmplitudeUserProperties({ 'Payments gateways': 'Braintree' });
        dispatch(setEventDataList(localEventsData));
        dispatch(
          braintreePurchase(
            payload.nonce,
            plan,
            null,
            null,
            token,
            analyticsParams,
            extendedEventsData,
            userUuid
          )
        );
      }
    );
  };

  const onPurchaseClick = () => {
    if (isBraintree) {
      braintreePurchaseClick();
    } else {
      stripePurchaseClick(null);
    }
  };

  const stripePurchaseClick = (
    paymentMethod = null,
    successPayment = null,
    failedPayment = null
  ) => {
    const paymentMethodName = paymentMethod?.walletName || 'Stripe';
    const paymentCardBrandName =
      paymentMethod?.card?.brand || eventsData.cardBrand || 'unknown';
    const paymentMethodObj = paymentMethod
      ? {
          methodName: paymentMethodName,
          stripeNonce: paymentMethod.paymentMethod.id,
        }
      : null;
    const localEventsData = {
      payment_method: paymentMethodName,
      cardBrand: paymentCardBrandName,
      card_brand: paymentCardBrandName,
      stripe_account_name: stripeAccountName,
    };
    const extendedEventsData = {
      ...eventsData,
      ...localEventsData,
    };
    setAmplitudeUserProperties({
      'Payments gateways': `Stripe [${stripeAccountName}]`,
    });
    dispatch(
      setEventDataList({
        'Payments gateways': `Stripe [${stripeAccountName}]`,
      })
    );
    dispatch(setEventDataList(localEventsData));
    sendAnalyticsEvents(EVENTS.subBuyTapped, extendedEventsData);
    dispatch(
      sendMainPurchaseRequest(
        paymentMethodObj,
        stripeInstance.current,
        card.current,
        name,
        plan,
        token,
        analyticsParams,
        extendedEventsData,
        userUuid,
        successPayment,
        failedPayment
      )
    );
  };

  const braintreePurchaseClick = () => {
    const cardNumber = card.current.value;
    const cardExpiration = expiration.current.value;
    const cardCVV = cvc.current.value;

    const onError = (error) => {
      dispatch(setCheckoutLoader({ show: false, type: '' }));
      dispatch(setShowPaymentError({ show: true, text: error.message }));
    };

    const fullEventsData = {
      ...eventsData,
      payment_method: 'Braintree',
    };
    sendAnalyticsEvents(EVENTS.subBuyTapped, fullEventsData);
    dispatch(setCheckoutLoader({ show: true, type: 'checkout' }));
    sendAnalyticsEvents(EVENTS.paymentAnimationScreen, fullEventsData);

    braintreeCardInstance.current.tokenize(
      {
        number: cardNumber,
        expirationDate: cardExpiration,
        cvv: cardCVV,
      },
      (error, payload) => {
        if (error) {
          console.error('Payment error', error);
          onError(error);
          return;
        }

        const cardBrand =
          payload?.details?.cardType || eventsData.cardBrand || 'unknown';
        const localEventsData = {
          payment_method: 'Braintree',
          cardBrand,
          card_brand: cardBrand,
        };
        const extendedEventsData = {
          ...eventsData,
          ...localEventsData,
        };
        setAmplitudeUserProperties({ 'Payments gateways': 'Braintree' });
        dispatch(setEventDataList(localEventsData));
        dispatch(
          braintreePurchase(
            payload.nonce,
            payload.binData,
            braintreeThreeDSInstance.current,
            plan,
            token,
            analyticsParams,
            extendedEventsData,
            userUuid
          )
        );
      }
    );
  };

  const supportMail = 'support@geozilla.com';

  const onLinkClick = ({ target: { id } }) => {
    const isTerms = id === 'terms-link';
    const isSupport = id === 'support-link';
    const links = {
      'terms-link': 'https://geozilla.com/terms-of-use/',
      'support-link': `mailto:${supportMail}`,
    };

    if (!(isTerms || isSupport)) return;
    window.open(links[id]);
  };

  useEffect(() => {
    if (showError) {
      navigate('/checkout/error');
    }
  }, [showError, navigate]);

  useEffect(() => {
    if (showCheckout && !showError) {
      dispatch(setIfCardFormShown(true));
      sendAnalyticsEvents(EVENTS.onboardingCheckoutShown, eventsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCheckout, showError, dispatch]);

  useEffect(() => {
    if (isSuccess) {
      const { periodPrice } = planDetails;
      pushFBEvent(
        {
          currency: 'USD',
          itemCount: 1,
          transactionId: subscriptionId,
          price: periodPrice,
          value: periodPrice,
        },
        `${userUuid}_purchase`
      );

      if (!isStage) {
        ReactGA.event('purchase', {
          currency: 'USD',
          value: periodPrice,
          transaction_id: subscriptionId,
          gclid: eventsData?.gclid || '',
          items: {
            item_id: plan,
            item_name: plan,
          },
        });
      }
      navigate('/checkout/success');
    }
  }, [
    isSuccess,
    navigate,
    planDetails,
    subscriptionId,
    plan,
    userUuid,
    eventsData,
  ]);

  const STORES = [
    {
      name: 'Google Play',
      icon: '../assets/checkout/GooglePlay.svg',
      width: '23px',
      height: '26px',
      grade: 4.5,
      rating: '412K',
    },
    {
      name: 'App Store',
      icon: '../assets/checkout/AppStore.svg',
      width: '18px',
      height: '22px',
      grade: 4.6,
      rating: '36.9K',
    },
  ];

  const renderStoreItem = (i, index) => (
    <StoreLi key={index}>
      <StyledItemStoreWrapper>
        <StyledItemStoreImg
          src={i.icon}
          alt={i.name}
          width={i.width}
          height={i.height}
        />
        {i.name}
      </StyledItemStoreWrapper>
      <StyledItemStore>{i.grade}</StyledItemStore>
      <img
        src={'../assets/checkout/store-stars.svg'}
        alt={i.name}
        width={116}
        height={20}
      />
      <StyledItemStore>
        {i.rating} {t('ratings')}
      </StyledItemStore>
    </StoreLi>
  );

  const onBackClick = () => {
    sendAnalyticsEvents(EVENTS.subBuyClosed, eventsData);
    dispatch(setCardholderName(''));
    dispatch(setPlan(defaultPlanId));
    dispatch(setShowCheckout(false));
    clearFields();
  };

  const clearFields = () => {
    if (
      !(
        card?.current &&
        expiration?.current &&
        cvc?.current &&
        cardholder?.current
      )
    )
      return;
    cardholder.current.value = '';
    if (!isBraintree) {
      const fieldsToClear = [card.current, expiration.current, cvc.current];
      fieldsToClear.forEach((field) => field.clear());
    } else if (braintreeCardInstance.current) {
      ['number', 'expirationDate', 'cvv'].forEach((field) =>
        braintreeCardInstance.current.clear(field)
      );
    }
  };

  useImperativeHandle(paymentCallback, () => ({
    onPurchaseClick,
  }));

  const content = (
    <>
      <CheckoutHeadWrapper>
        {!implemented && (
          <StyledBackButton onClick={onBackClick} id="come-back-button">
            <ArrowLeftIcon />
          </StyledBackButton>
        )}
        <Title>{t('checkout')}</Title>
      </CheckoutHeadWrapper>
      {cardFormVisited && paymentRequest && (
        <Elements stripe={stripePromise}>
          <PaymentRequestContainer>
            <PaymentRequestButtonElement
              options={{
                paymentRequest,
                id: 'apple-google-pay-button',
                style: {
                  paymentRequestButton: {
                    height: '56px',
                    theme: 'dark',
                    type: 'default',
                  },
                },
              }}
            />
          </PaymentRequestContainer>
        </Elements>
      )}
      {isBraintree ? (
        <Braintree
          btToken={btToken}
          braintreeInstance={braintreeInstance.current}
          cardInstance={braintreeCardInstance}
          threeDSInstance={braintreeThreeDSInstance}
          card={card}
          expiration={expiration}
          cvc={cvc}
          cardholder={cardholder}
          planDetails={planDetails}
        />
      ) : (
        load && (
          <Stripe
            card={card.current}
            expiration={expiration.current}
            cvc={cvc.current}
            inputRef={cardholder}
            implemented={implemented}
          />
        )
      )}
      <CardIcons />
      <AppButton
        displayStickyBtn={!implemented}
        disabled={!isFormValid}
        onClick={() => onPurchaseClick(null)}
        bottomPosition={'24px'}
        customBeforeBg={'none'}
        position={'sticky'}
        customStickyWidth={'100%'}
        displayStubBtn={false}
        customId={`${isBraintree ? 'braintree' : 'stripe'}-continue-button`}
      >
        {t('continue')}
      </AppButton>
      {paypal.current && (
        <AlternativePaymentButtons>
          <PayWithText>{t('or')}</PayWithText>
          <PaypalButton id={'paypal-pay-button'} onClick={onPayPalClick} />
        </AlternativePaymentButtons>
      )}
      <StoreWrapper>
        <StoreButtons>{STORES.map(renderStoreItem)}</StoreButtons>
        {planDetails && (
          <Disclaimer
            dangerouslySetInnerHTML={{
              __html: t(isShortDisclaimer ? 'short_disclaimer' : 'disclaimer', {
                nowPrice: `$${plansPrices?.paymentPrice}`,
                disclaimer: t(planDetails.disclaimer),
                fullPrice: `$${plansPrices?.monthlyPrice}`,
                mail: supportMail,
              }),
            }}
            onClick={(e) => onLinkClick(e)}
          />
        )}
      </StoreWrapper>
      {isStage && (
        <input
          type="hidden"
          id="test-skip-checkout"
          onClick={() => navigate('/checkout/success')}
        />
      )}
    </>
  );

  return implemented ? (
    content
  ) : (
    <AppContainer
      customPadding={windowWidth >= 450 ? '82px 24px 24px' : '30px 24px 24px'}
    >
      {content}
    </AppContainer>
  );
};

export default CheckoutScreen;
